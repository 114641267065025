body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
}

button,
h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
span,
div,
a {
  font-family: "Raleway", sans-serif;
}


.__dark {
  background: #ccc2;
  min-height: 97vh;
}

.__model {
  width: 45vw !important;
}

.__model .ant-modal-content {
  padding: 55px;
  border-radius: 20px;
}

.__model_design {
  text-align: center
}

.__model_design img {
  filter: hue-rotate(106deg);
  width: 140px;
}

.__model_design .__ok_button{
  background: #ffffff;
  border: 1px solid #ddd;
  color: #000000;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  width: 100px;
}